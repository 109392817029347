import { Select, Space, message } from 'antd';
import React, { useEffect, useState } from 'react';
import Axios from '../../../config/axios';

function FilterGenerateSPCard({ onFinish }) {
    const [data, setData] = useState({
        departments: [],
        main_processes: [],
        sub_processes: [],
    });
    const [filters, setFilters] = useState({
        departments: [],
        main_processes: [],
        sub_processes: [],
    });

    const [page] = useState(1);
    const [perpage] = useState(15);
    const [sort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });

    useEffect(() => {
        var filterData = {};
        if (filters.departments.length > 0) {
            filterData = {...filterData, "plan_name": filters.departments}
        }
        
        if (filters.main_processes.length > 0) {
            filterData = {...filterData, "main_process": filters.main_processes}
        }
        
        if (filters.sub_processes.length > 0) {
            filterData = {...filterData, "sub_process_name": filters.sub_processes}
        }

        onFinish(filterData);
    }, [filters]);

    const handleChangeSelectMultiple = (name, value) => {
        setFilters({
            ...filters,
            [name]: value.map((item) => item.key),
        });
    };

    const handleClear = (name) => {
        setFilters({ ...filters, [name]: [] });
        setData({ ...data, [name]: [] });
    };

    const getDepartments = (keyword = null) => {
        Axios.get(`/plan/list`, {
            params: {
                keyword,
                limit: perpage,
                page,
                sortBy: `${sort.sort}:${sort.order}`,
            },
        })
            .then((res) => {
                const resData = res.data.data.map((item) => {
                    return {
                        value: item.name,
                        label: item.name,
                    };
                });
                setData({ ...data, departments: resData });
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    const getMainProcess = (keyword = null) => {
        Axios.get('/line-process/listmain', {
            params: {
                keyword,
                department:
                    filters.departments.length > 0 ? filters.departments : null,
                limit: perpage,
                page,
                sortBy: `${sort.sort}:${sort.order}`,
            },
        })
            .then((res) => {
                const resData = res.data.data.map((item) => {
                    return {
                        value: item.main_process,
                        label: item.main_process,
                    };
                });
                setData({ ...data, main_processes: resData });
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    const getSubProcess = (keyword = null) => {
        Axios.get('/line-process/listsub', {
            params: {
                keyword,
                limit: perpage,
                department:
                    filters.departments.length > 0 ? filters.departments : null,
                mainProcesses:
                    filters.main_processes.length > 0
                        ? filters.main_processes
                        : null,
                page,
                sortBy: `${sort.sort}:${sort.order}`,
            },
        })
            .then((res) => {
                const resData = res.data.data.map((sub) => {
                    return {
                        value: sub.sub_process,
                        label: sub.sub_process,
                    };
                });
                setData({ ...data, sub_processes: resData });
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    return (
        <Space>
            <Select
                allowClear
                onClear={() => handleClear('departments')}
                style={{ width: '200px' }}
                labelInValue
                onChange={(value) =>
                    handleChangeSelectMultiple('departments', value)
                }
                onFocus={() => getDepartments()}
                showSearch
                onSearch={(value) => getDepartments(value)}
                filterOption={false}
                mode={'multiple'}
                placeholder="Filter Departemen"
            >
                {data.departments &&
                    data.departments.map((item) => (
                        <Select.Option key={item.value} value={item.value}>
                            {item.label}
                        </Select.Option>
                    ))}
            </Select>
            <Select
                allowClear
                onClear={() => handleClear('main_processes')}
                style={{ width: '200px' }}
                labelInValue
                onChange={(value) =>
                    handleChangeSelectMultiple('main_processes', value)
                }
                onFocus={() => getMainProcess()}
                showSearch
                onSearch={(value) => getMainProcess(value)}
                filterOption={false}
                mode={'multiple'}
                placeholder="Filter Main Process"
            >
                {data.main_processes &&
                    data.main_processes.map((item) => (
                        <Select.Option key={item.value} value={item.value}>
                            {item.label}
                        </Select.Option>
                    ))}
            </Select>
            <Select
                allowClear
                onClear={() => handleClear('sub_processes')}
                style={{ width: '200px' }}
                labelInValue
                onChange={(value) =>
                    handleChangeSelectMultiple('sub_processes', value)
                }
                onFocus={() => getSubProcess()}
                showSearch
                onSearch={(value) => getSubProcess(value)}
                filterOption={false}
                mode={'multiple'}
                placeholder="Filter Main Process"
            >
                {data.sub_processes &&
                    data.sub_processes.map((item) => (
                        <Select.Option key={item.value} value={item.value}>
                            {item.label}
                        </Select.Option>
                    ))}
            </Select>
        </Space>
    );
}

export default FilterGenerateSPCard;
