import {
    Card,
    Layout,
    Col,
    Form,
    Typography,
    Input,
    Row,
    Space,
    Table,
    Switch,
    message,
    Dropdown,
    Menu,
    Popconfirm,
} from 'antd';
import { useEffect, useState, Fragment, useRef } from 'react';
import axios from 'axios';
import { url } from '../../../constant/url';
import Axios from '../../../config/axios';
import Can from '../../../components/Can';
import MergeLabelInternal from './components/MergeLabelInternal';
import GenerateLabelModal from './components/GenerateLabelModal';
import FilterLabelInternal from './components/FilterLabelInternal';
import ExportLabelInternal from './components/ExportLabelInternal';
import GenerateLabelHistory from './components/GenerateLabelHistory';
import { DeleteOutlined, EllipsisOutlined, QrcodeOutlined } from '@ant-design/icons';
import './Custom.css';
const { Title, Text } = Typography;
const { Content } = Layout;
const layout = {
    labelCol: {
        xs: 24,
        lg: 24,
        md: 24,
    },
    wrapperCol: {
        xs: 24,
        lg: 24,
        md: 24,
    },
};

function GenerateLabelInternal({ history }) {
    const inputRef = useRef(null);
    const [qrcode, setQrcode] = useState('');
    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);

    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState([
        {
            order: 'descend',
            field: 'diameter',
        },
        {
            order: 'descend',
            field: 'panjang',
        },
        {
            order: 'ascend',
            field: 'material',
        },
    ]);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [labelInternal, setLabelInternal] = useState([]);
    const [isCreate, setIsCreate] = useState(false);
    const [errors, setErrors] = useState(null);
    const [isMerge, setIsMerge] = useState(false);
    const [isGenerate, setIsGenerate] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [data, setData] = useState({
        qrcode: '',
        qrcode_generate: '',
    });
    const [isExportVisible, setIsExportVisible] = useState(false);
    const [showGenerated, setShowGenerated] = useState(false);

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        if (!Array.isArray(sorter)) {
            setSort([sorter]);
        } else {
            setSort(sorter);
        }
    };

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded ? [record._id] : [];
        setExpandedRowKeys(newExpandedRowKeys);
    };

    useEffect(() => {
        getData();
    }, [keyword, page, perpage, sort, showGenerated]);

    const getData = (filters = null) => {
        setSelectedRows([]);
        setSelectedData([]);
        setLoading(true);
        Axios.get('/generate-label', {
            params: {
                keyword,
                filters,
                perpage: perpage,
                showGenerated,
                page,
                sort,
            },
        })
            .then((res) => {
                setLabelInternal(res.data.data);
                setTotal(res.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        setSelectedRows(newSelectedRowKeys);
        setSelectedData(selectedRows);
    };

    const handleMerge = () => {
        setIsMerge(true);
    };

    const handleSave = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
            .post(
                `${url}/generate-label/scan-qrcode`,
                {
                    qrcode: data.qrcode,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                },
            )
            .then((res) => {
                setLoading(false);
                message.success(res.data.message);
                setData({
                    ...data,
                    qrcode: '',
                });
                getData();
            })
            .catch((err) => {
                setData({
                    ...data,
                    qrcode: '',
                });

                if (err.response) {
                    setErrors(err.response.data.errors);
                    message.error(err.response.data.message);
                }
                setLoading(false);
            });
    };

    const handleSaveGenerate = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
            .post(
                `${url}/generate-label/scan-generate-qrcode`,
                {
                    qrcode_generate: data.qrcode_generate,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                },
            )
            .then((res) => {
                setLoading(false);
                message.success(res.data.message);
                setData({
                    ...data,
                    qrcode_generate: '',
                });
                getData();
            })
            .catch((err) => {
                setData({
                    ...data,
                    qrcode_generate: '',
                });

                if (err.response) {
                    setErrors(err.response.data.errors);
                    message.error(err.response.data.message);
                }
                setLoading(false);
            });
    };

    const handleGenerate = () => {
        setIsGenerate(true);
    };

    const handleGenerateSelected = () => {
        setLoading(true);
        Axios.post(`/generate-label/multiple/generate`, {
            spcards: selectedRows,
        })
            .then(({ data }) => {
                message.success(data.message);
                getData();
            })
            .catch((error) => {
                if (error.response) {
                    let errorMessage = '';
                    if (typeof error.response.data.message === 'object') {
                        const { spcard } = error.response.data.message;
                        errorMessage = spcard[0];
                    } else {
                        errorMessage = error.response.data.message;
                    }
                    message.error(errorMessage);
                }
            })
            .finally(() => {
                setLoading(false);
                setSelectedRows([]);
            });
    };

    const handleDelete = (id) => {
        setLoading(true);
        Axios.delete(`/generate-label/${id}`)
            .then(({ data }) => {
                message.success(data.message);
                getData();
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const rowSelection = {
        preserveSelectedRowKeys: false,
        selectedRowKeys: selectedRows,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            disabled: record.is_generate === 1,
            className: record.is_generate === 1 ? 'row-generate' : '',
        }),
    };

    const rowClassName = (record) => {
        return record.is_generate === 1 ? 'row-generate' : '';
    };
    const columns = [
        {
            title: 'Code',
            dataIndex: 'qrcode',
            key: 'qrcode',
            sorter: {
                multiple: 1,
            },
        },
        {
            title: 'Scan Time',
            dataIndex: 'scan_time',
            key: 'scan_time',
            // render: (value) => moment(value.scan_time).format('YYYY-MM-DD HH:mm:ss'),
            sorter: {
                multiple: 1,
            },
        },
        {
            title: 'Part Number',
            dataIndex: 'part_number',
            key: 'part_number',
            sorter: {
                multiple: 2,
            },
        },
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            key: 'part_name',
            sorter: {
                multiple: 3,
            },
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            sorter: true,
        },
        {
            title: 'Main Proccess',
            dataIndex: 'main_process',
            key: 'main_process',
            sorter: true,
        },
        {
            title: 'Line Proccess',
            dataIndex: 'line_process',
            key: 'line_process',
            sorter: true,
        },

        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            sorter: true,
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            sorter: true,
        },
        {
            title: 'Section',
            dataIndex: 'section',
            key: 'section',
            sorter: true,
        },
        {
            title: 'Qty SP Card',
            dataIndex: 'qty_spcard',
            key: 'qty_spcard',
            sorter: true,
        },
        {
            title: 'Qty Current',
            dataIndex: 'qty_current',
            key: 'qty_current',
            sorter: {
                multiple: 4,
            },
        },
        {
            title: 'Qty ERP (SNP)',
            dataIndex: 'qty_erp',
            key: 'qty_erp',
            sorter: {
                multiple: 4,
            },
        },
        {
            title: 'Qty NG',
            dataIndex: 'qty_ng',
            key: 'qty_ng',
            sorter: {
                multiple: 4,
            },
            render: (qty_ng) => (qty_ng ? qty_ng : 0),
        },
        // {
        //     title: 'Qty Internal',
        //     dataIndex: 'qty_internal',
        //     key: 'qty_internal',
        //     sorter: {
        //         multiple: 5,
        //     },
        // },
        {
            title: 'Total Label',
            dataIndex: 'total_label',
            key: 'total_label',
            sorter: {
                multiple: 6,
            },
        },
        {
            title: 'Generate Remaining',
            dataIndex: 'generate_remaining',
            key: 'generate_remaining',
            sorter: true,
        },
        {
            title: 'Print Total',
            dataIndex: 'print_total',
            key: 'print_total',
            sorter: true,
        },
        {
            title: 'Print Remaining',
            dataIndex: 'print_remaining',
            key: 'print_remaining',
            sorter: true,
        },
        {
            title: '',
            dataIndex: '_id',
            key: 'id',
            sorter: false,
            fixed: 'right',
            width: 50,
            render: (_, record) => {
                const items = [
                    {
                        key: '1',
                        danger: true,
                        label: (
                            <Popconfirm
                                title="Are you sure delete this SP Card?"
                                okText="OK"
                                okType="danger"
                                onConfirm={() => handleDelete(record._id)}
                            >
                                <DeleteOutlined /> Delete
                            </Popconfirm>
                        ),
                    },
                ];

                return (
                    <Dropdown
                        className="pointer"
                        overlay={
                            <Menu>
                                <Menu.Item key="1" danger>
                                    <Popconfirm
                                        title="Are you sure delete this SP Card?"
                                        onConfirm={() =>
                                            handleDelete(record._id)
                                        }
                                        okText="OK"
                                        okType="danger"
                                    >
                                        <DeleteOutlined /> Delete
                                    </Popconfirm>
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={['click']}
                    >
                        <span
                            className="pointer"
                            onClick={(e) => e.preventDefault()}
                        >
                            <i className="mdi mdi-dots-vertical" />
                        </span>
                    </Dropdown>
                );
            },
        },
    ];

    const hasSelected = selectedRows.length > 0;
    const styleForm = {
        marginTop: '-20px',
    };
    return (
        <div>
            <ul className="breadcumb">
                <li className="active">Generate S.P.CARD DEB</li>
            </ul>
            <Card>
                <Row justify="space-between" gutter={[16, 16]}>
                    <Col>
                        <Space>
                            <Can accessTo="Generate Label Intenal">
                                <GenerateLabelModal
                                    isVisible={isGenerate}
                                    setIsVisible={setIsGenerate}
                                    onFinish={getData}
                                    data={selectedData[selectedRows.length - 1]}
                                    disabled={selectedRows.length !== 1}
                                />
                            </Can>
                            <Can accessTo="Merge Generate Label">
                                <MergeLabelInternal
                                    isVisible={isMerge}
                                    setIsVisible={setIsMerge}
                                    onFinish={getData}
                                    data={selectedData[selectedRows.length - 1]}
                                    disabled={selectedRows.length !== 1}
                                />
                            </Can>
                            <Can accessTo="Export Generate Label Internal">
                                <ExportLabelInternal
                                    isVisible={isExportVisible}
                                    setIsVisible={setIsExportVisible}
                                />
                            </Can>

                            <Space>
                                <span>Generated</span>
                                <Switch
                                    checkedChildren="Hide"
                                    unCheckedChildren="Show"
                                    size="default"
                                    onChange={() =>
                                        setShowGenerated(!showGenerated)
                                    }
                                />
                            </Space>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <FilterLabelInternal onFinish={getData} />
                            <Input.Search
                                allowClear="true"
                                onSearch={(value) => {
                                    setPage(1);
                                    setKeyword(value);
                                }}
                                placeholder="Search..."
                                inputStyle={{
                                    whiteSpace: 'nowrap',
                                    overflowX: 'auto',
                                }}
                                style={{ width: '30rem' }}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row justify="space-between" style={{ marginTop: 10 }}>
                    <Space>
                        <Can accessTo="Scan Generate Label Intenal">
                            {/* <GenerateLabelModal
                                    isVisible={isGenerate}
                                    setIsVisible={setIsGenerate}
                                    onFinish={getData}
                                    data={selectedData[selectedRows.length - 1]}
                                    disabled={selectedRows.length !== 1}
                                /> */}
                            <Form layout="vertical">
                                <Fragment>
                                    <Form.Item>
                                        <Input
                                            ref={inputRef}
                                            prefix={<QrcodeOutlined />}
                                            type="primary"
                                            loading={loading}
                                            onChange={(e) =>
                                                handleChange(
                                                    'qrcode',
                                                    e.target.value,
                                                )
                                            }
                                            value={data.qrcode}
                                            placeholder="Scan Qr Code"
                                            onPressEnter={handleSave}
                                        />
                                    </Form.Item>
                                </Fragment>
                            </Form>
                        </Can>
                        <Can accessTo="Scan & Generate Label Intenal">
                            <Form layout="vertical">
                                <Fragment>
                                    <Form.Item>
                                        <Input
                                            prefix={<QrcodeOutlined />}
                                            type="primary"
                                            loading={loading}
                                            onChange={(e) =>
                                                handleChange(
                                                    'qrcode_generate',
                                                    e.target.value,
                                                )
                                            }
                                            value={data.qrcode_generate}
                                            placeholder="Scan & Generate Qr Code"
                                            onPressEnter={handleSaveGenerate}
                                        />
                                    </Form.Item>
                                </Fragment>
                            </Form>
                        </Can>
                    </Space>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col xs={24}>
                        <Table
                            scroll={{ x: 'max-content' }}
                            columns={columns}
                            dataSource={labelInternal}
                            pagination={{
                                current: page,
                                total,
                                showSizeChanger: true,
                                pageSizeOptions: [
                                    '10',
                                    '20',
                                    '50',
                                    '100',
                                    total.toString(),
                                ],
                            }}
                            loading={loading}
                            rowKey={(data) => data._id}
                            onChange={handleTableChange}
                            rowSelection={rowSelection}
                            expandable={{
                                expandedRowKeys: expandedRowKeys,
                                onExpand: handleExpand,
                                expandedRowRender: (record) => (
                                    <GenerateLabelHistory
                                        key={`history-${record._id}`}
                                        id={record._id}
                                        rows={expandedRowKeys}
                                    />
                                ),
                            }}
                            rowClassName={rowClassName}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    );
}

export default GenerateLabelInternal;
