import {
    Col,
    Input,
    message,
    Row,
    Button,
    Table,
} from 'antd';
import Axios from '../../config/axios';
import Can from '../../components/Can';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ExpandedRow from './ExpandedRow';
import _ from 'underscore';

function RestoreHistory(history) {
    const columns = [
        {
            title: 'No.',
            key: 'index',
            render: (text, record, index) => (page - 1) * perpage + index + 1,
            width: 50,
        },
        {
            title: 'Qr Code',
            dataIndex: 'spcard_qrcode',
            key: 'spcard_qrcode',
            width: 300,
        },
        {
            title: 'Part No',
            dataIndex: 'part_number',
            key: 'part_number',
            sorter: false,
        },
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            key: 'part_name',
            sorter: false,
        },
        {
            title: 'Main Process',
            dataIndex: 'main_process',
            key: 'main_process',
        },
        {
            title: 'Sub Process',
            dataIndex: 'sub_process_name',
            key: 'sub_process_name',
        },
        {
            title: 'Line Process',
            dataIndex: 'line_process_name',
            key: 'line_process_name',
        },
        {
            title: 'Qty NG Before',
            dataIndex: 'qty_before',
            key: 'qty_before',
        },
        {
            title: 'Qty NG After',
            dataIndex: 'qty_after',
            key: 'qty_after',
        },
        {
            title: 'Qty Current',
            dataIndex: 'qty_current',
            key: 'qty_current',
        },
        {
            title: 'Restore By',
            dataIndex: 'created_by_name',
            key: 'created_by_name',
        },
        {
            title: 'Restore Time',
            dataIndex: 'created_at',
            key: 'created_at',
        }
    ];

    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });

    const [sortDetail, setSortDetail] = useState({
        sort: 'updated_at',
        order: 'descend',
    });

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded ? [record.id] : [];
        setExpandedRowKeys(newExpandedRowKeys);
    };
    const [loading, setLoading] = useState(false);
    const [pageDetail, setPageDetail] = useState(1);
    const [perPageDetail, setPerPageDetail] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [keywordDetails, setKeywordDetails] = useState('');
    const [ngData, setNgData] = useState([]);

    const [data, setData] = useState({
        qrcode: '',
    });

    console.log(ngData)

    useEffect(() => {
        getHistoryData();
    }, [keyword, page, perpage, sort]);

    const getHistoryData = () => {
        setLoading(true);
        Axios.get('/reverse-ng')
        .then((res) => {
            setNgData(res.data.data);
        })
        .catch((err) => {
            if (err.response) {
                message.error(err.response.data.message);
                if (err.response.status === 401) {
                    localStorage.removeItem('token');
                }
            }
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });
    };

    console.log(ngData)

    return (
        <div>
            <ul className="breadcumb">
                <li className="active">Restore NG</li>
            </ul>
            <div className="content">
                <Row justify="space-between">
                    <Col>
                        <Can accessTo={'Edit NG'}>
                            <Link to="/restore-ng/edit">
                            <Button
                                type="primary"
                                style={{
                                    backgroundColor: '#1b2086',
                                    borderColor: '#1b2086',
                                }}
                            >
                                Restore NG
                            </Button>
                            </Link>
                        </Can>
                    </Col>
                    <Col>
                        <Input.Search
                            allowClear="true"
                            onSearch={(value) => {
                                setPage(1);
                                setKeyword(value);
                            }}
                            placeholder="Search..."
                        />
                    </Col>
                </Row>
                <Row className="mt-40">
                    <Col xs={24}>
                        <Table
                            scroll={{ x: 'max-content' }}
                            columns={columns}
                            dataSource={ngData}
                            pagination={{
                                pageSize: perpage,
                                current: page,
                                total: total,
                                showSizeChanger: true,
                            }}
                            loading={loading}
                            // expandable={{
                            //     expandedRowKey: expandedRowKeys,
                            //     onExpand: handleExpand,
                            //     expandedRowRender: (record) => (
                            //         <ExpandedRow 
                            //             key={`ng-data-${record.id}`}
                            //             expandedData={record.ng_data}
                            //         />
                            //     )
                        
                            // }}
                            rowKey={(data) => data.id}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default RestoreHistory;
