import {
    Col,
    Input,
    message,
    Row,
    Form,
    Table,
} from 'antd';
import Axios from '../../config/axios';
import Can from '../../components/Can';
import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { url } from '../../constant/url';
import ExpandedRow from './ExpandedRow';
import {
    QrcodeOutlined,
} from '@ant-design/icons';
import _ from 'underscore';

function RestoreNg(history) {
    const columns = [
        {
            title: 'No.',
            key: 'index',
            render: (text, record, index) => (page - 1) * perpage + index + 1,
            width: 50,
        },
        {
            title: 'Qr Code',
            dataIndex: 'qrcode',
            key: 'qrcode',
            width: 300,
        },

        {
            title: 'Part Name',
            dataIndex: 'part_name',
            key: 'part_name',
            sorter: false,
        },
        {
            title: 'Part No',
            dataIndex: 'part_number',
            key: 'part_number',
            sorter: false,
        },
        {
            title: 'Main Process',
            dataIndex: 'main_process',
            key: 'main_process',
        },
        {
            title: 'Sub Process',
            dataIndex: 'sub_process',
            key: 'sub_process',
        },
        {
            title: 'Line Process',
            dataIndex: 'line_process_name',
            key: 'line_process_name',
        },
        {
            title: 'Qty NG',
            dataIndex: 'qty_ng',
            key: 'qty_ng',
        }
    ];

    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });

    const [sortDetail, setSortDetail] = useState({
        sort: 'updated_at',
        order: 'descend',
    });

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded ? [record.id] : [];
        setExpandedRowKeys(newExpandedRowKeys);
    };
    const [loading, setLoading] = useState(false);
    const [pageDetail, setPageDetail] = useState(1);
    const [perPageDetail, setPerPageDetail] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [keywordDetails, setKeywordDetails] = useState('');
    const [ngData, setNgData] = useState([]);

    const [data, setData] = useState({
        qrcode: '',
    });

    useEffect(() => {

    }, [keyword, page, perpage, sort]);

    useEffect(() => {

    }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

    const getNgData = () => {
        setLoading(true);
        Axios.get('/reverse-ng/qrcode', {
            params: {
                qrcode: data.qrcode
            }
        })
        .then((res) => {
            setNgData([
                res.data.data
            ]);
        })
        .catch((err) => {
            if (err.response) {
                message.error(err.response.data.message);
                if (err.response.status === 401) {
                    localStorage.removeItem('token');
                }
            }
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });
    };

    const handleChangeQrCode = (value) => {
        setData({
            ...data,
            qrcode: value
        })
    };

    console.log(ngData)

    return (
        <div>
            <ul className="breadcumb">
                <li>Restore NG</li>
                <li className="active">Edit NG</li>
            </ul>
            <div className="content">
                <Row justify="space-between">
                    <Col>
                        <Form layout="vertical">
                            <Fragment>
                                <Form.Item>
                                    <Input
                                        // ref={inputRef}
                                        prefix={<QrcodeOutlined />}
                                        type="primary"
                                        loading={loading}
                                        onChange={(e) =>
                                            handleChangeQrCode(e.target.value)
                                        }
                                        value={data.qrcode}
                                        placeholder="Scan Qr Code"
                                        onPressEnter={getNgData}
                                    />
                                </Form.Item>
                            </Fragment>
                        </Form>
                    </Col>
                    <Col>
                        <Input.Search
                            allowClear="true"
                            onSearch={(value) => {
                                setPage(1);
                                setKeyword(value);
                            }}
                            placeholder="Search..."
                        />
                    </Col>
                </Row>
                <Row className="mt-40">
                    <Col xs={24}>
                        <Table
                            scroll={{ x: 'max-content' }}
                            columns={columns}
                            dataSource={ngData}
                            pagination={{
                                pageSize: perpage,
                                current: page,
                                total: total,
                                showSizeChanger: true,
                            }}
                            loading={loading}
                            expandable={{
                                expandedRowKey: expandedRowKeys,
                                onExpand: handleExpand,
                                expandedRowRender: (record) => (
                                    <ExpandedRow 
                                        key={`ng-data-${record.id}`}
                                        expandedData={record.ng_data}
                                    />
                                )
                        
                            }}
                            rowKey={(data) => data.id}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default RestoreNg;
