import { Card, Row, Col, Input, Table, InputNumber, Button, message } from 'antd';
import { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import moment from 'moment';

function ExpandedRow({ id, rows, expandedData }) {
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState(expandedData || []);
    const [dataForPost, setDataForPost] = useState([]);

    useEffect(() => {
        // Set the initial total and filtered data
        setTotal(expandedData?.length || 0);
        setFilteredData(expandedData || []);
        const newDataForPost = expandedData?.map((item) => ({id: item.id, qty: item.qty}))
        setDataForPost(newDataForPost)
    }, [expandedData]);

    const handleChangeQty = (id, value) => {
        const updatedData = dataForPost.map(item => item.id === id ? { ...item, qty: value} : item)
        setDataForPost(updatedData)
    }

    const columns = [
        {
            title: 'No.',
            key: 'index',
            render: (text, record, index) => (page - 1) * perpage + index + 1,
            width: 50,
        },
        {
            title: 'Main Process',
            dataIndex: 'main_process',
            key: 'main_process',
            width: 120
        },
        {
            title: 'Line Process',
            dataIndex: 'line_process_name',
            key: 'line_process_name',
            width: 150
        },
        {
            title: 'NG Name',
            dataIndex: 'ng_name',
            key: 'ng_name',
            width: 200
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
            key: 'created_by',
        },
        {
            title: 'Created Time',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => (
                moment(created_at).format('Y-M-d H:m')
            )
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
            render: (qty, record) => (
                <InputNumber 
                    min={0}
                    defaultValue={qty}
                    onChange={(value) => handleChangeQty(record.id, value)}
                />
            )
        },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });

        // Optional: Add sorting functionality here if needed
    };

    const handleSearch = (value) => {
        setKeyword(value);
        if (value) {
            const filtered = expandedData.filter((item) =>
                Object.values(item).some((field) =>
                    String(field).toLowerCase().includes(value.toLowerCase())
                )
            );
            setFilteredData(filtered);
            setTotal(filtered.length);
        } else {
            setFilteredData(expandedData || []);
            setTotal(expandedData?.length || 0);
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        const formData = {
            ng: dataForPost
        }

        console.log(formData)
        Axios
            .post('/reverse-ng', formData)
            .then(({data}) => {
                message.success(data.message);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <Card>
            <Row justify="space-between">
                <Col>
                    <Button 
                        type='primary'
                        style={{
                            backgroundColor: '#1b2086',
                            borderColor: '#1b2086',
                        }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Col>
                <Col>
                    <Input.Search
                        allowClear={true}
                        onSearch={handleSearch}
                        placeholder="Search..."
                    />
                </Col>
            </Row>
            <Row className="mt-40">
                <Col xs={24}>
                    <Table
                        scroll={{ x: true }}
                        columns={columns}
                        dataSource={filteredData}
                        pagination={{
                            total,
                            pageSize: perpage,
                            current: page,
                            showSizeChanger: true,
                            pageSizeOptions: [5, 10, 20, 50],
                        }}
                        loading={loading}
                        rowKey={(data) => data.id}
                        onChange={handleTableChange}
                    />
                </Col>
            </Row>
        </Card>
    );
}

export default ExpandedRow;
