import {
    Button,
    Col,
    Input,
    message,
    Row,
    Avatar,
    Upload,
    Select,
    Image,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './MasterSpForm.css';
import axios from 'axios';
import { url, storage_path } from '../../constant/url';
import profile from '../../assets/profile.jpg';
import { LeftOutlined } from '@ant-design/icons';

const { Option } = Select;
const categoryType = ['Non Assy', 'Assy Non Direct', 'Assy Direct', 'Non SPCARD'];

function EditMasterSpCard({ history, match }) {
    const role = localStorage.getItem('data');
    const roleObject = JSON.parse(role);

    const roleName = roleObject.role_name;

    const [data, setData] = useState({
        part_number: '',
        part_name: '',
        customer_id: '',
        material_code: '',
        main_process_name: '',
        main_process_id: '',
        customer_name: '',
        model: '',
        sub_process: '',
        line_processes: [],
        main_processes: [],
        material: '',
        diameter: '',
        thickness: '',
        panjang: '',
        toleransi: '',
        jenis_mesin: '',
        nomer_mesin: '',
        mesin_cutting: '',
        nomer_memori: '',
        stopper: '',
        bending_mesin: '',
        bending_jig: '',
        packing: '',
        raw_material: '',
        panjang_raw_material: '',
        negara_asal: '',
        category_part: '',

        qty_std: '',
        qty_int: '',
        qty_erp: '',
        prefix: '',
        plan_id: '',
        plan_name: '',
        line_internal: '',
        route_internal: '',
        infix: '',
        suffix: '',
        image: '',
        image_url: '',
    });

    const [page] = useState(1);
    const [perpage] = useState(10);
    const [sort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [mainProcess, setMainProcess] = useState([]);
    const [lineProcess, setLineProcess] = useState([]);
    const [plans, setPlan] = useState([]);
    const [partParents, setPartParents] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios
            .get(`${url}/master-spcard/${match.params.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setData({
                    ...data,
                    part_number: res.data.data.part_number,
                    part_name: res.data.data.part_name,
                    customer_id: res.data.data.customer_id,
                    customer_name: res.data.data.customer_name,
                    plan_id: res.data.data.plan_id,
                    plan_name: res.data.data.plan_name,
                    model: res.data.data.model,
                    material: res.data.data.material,
                    material_code: res.data.data.material_code,
                    diameter: res.data.data.diameter,
                    thickness: res.data.data.thickness,
                    panjang: res.data.data.panjang,
                    toleransi: res.data.data.toleransi,
                    jenis_mesin: res.data.data.jenis_mesin,
                    line_internal: res.data.data.line_internal,
                    main_process_name: res.data.data.main_process,
                    main_process_id: res.data.data.main_process,
                    route_internal: res.data.data.route_internal,
                    nomer_mesin: res.data.data.nomer_mesin,
                    mesin_cutting: res.data.data.mesin_cutting,
                    nomer_memori: res.data.data.nomer_memori,
                    stopper: res.data.data.stopper,
                    bending_mesin: res.data.data.bending_mesin,
                    bending_jig: res.data.data.bending_jig,
                    packing: res.data.data.packing,
                    raw_material: res.data.data.raw_material,
                    panjang_raw_material: res.data.data.panjang_raw_material,
                    negara_asal: res.data.data.negara_asal,
                    category_part: res.data.data.category_part,

                    qty_std: res.data.data.qty_std,
                    qty_int: res.data.data.qty_int,
                    qty_erp: res.data.data.qty_erp,
                    prefix: res.data.data.prefix,
                    infix: res.data.data.infix,
                    suffix: res.data.data.suffix,
                    sub_process: res.data.data.sub_process,
                    image_url: res.data.data.image_url,
                    main_processes: res.data.data.tbm_spcard_main_detail.map(
                        (x) => {
                            return {
                                key: x.line_process_id,
                                value: x.line_process_id,
                                label: x.main_process_name,
                            };
                        },
                    ),
                    line_processes: res.data.data.tbm_spcard_line_detail.map(
                        (x) => {
                            return {
                                key: x.line_process_id,
                                value: x.line_process_id,
                                label:
                                    x.sub_process_name +
                                    ' - ' +
                                    x.line_process_name,
                            };
                        },
                    ),
                    category_type: res.data.data.category_type ? res.data.data.category_type.toUpperCase() : '',
                    part_parent_id: res.data.data.part_parent ? res.data.data.part_parent._id: '',
                    part_parent:
                        res.data.data.part_parent ?
                        res.data.data.part_parent.map((x) => {
                            return {
                                key: x._id,
                                value: x._id,
                                label: x.part_number + ' - ' + x.part_name,
                            };
                        }) : '',
                    part_children:
                        res.data.data.part_children ?
                        res.data.data.part_children.map((x) => {
                            return {
                                key: x._id,
                                value: x._id,
                                label: x.part_number + ' - ' + x.part_name,
                            };
                        }) : '',
                });
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const handleChange = (name, value) => {
        setErrors({
            ...errors,
            [name]: '',
        });

        setData({
            ...data,
            [name]: value,
        });
    };

    const handleReset = () => {
        setData({
            part_number: '',
            part_name: '',
            department: '',
            customer_id: '',
            customer_name: '',
            model: '',
            main_processes: [],
            line_processes: [],
            material: '',
            diameter: '',
            thickness: '',
            panjang: '',
            jenis_mesin: '',
            nomer_mesin: '',
            mesin_cutting: '',
            nomer_memori: '',
            stopper: '',
            bending_mesin: '',
            bending_jig: '',
            packing: '',
            qty_int: '',
            qty_erp: '',
            raw_material: '',
            qty_std: '',
            image: '',
            image_url: '',
            category_type: '',
            part_parent_id: '',
            part_children: [],
        });
    };

    const handleBeforeUpload = (file) => {
        setData({
            ...data,
            image: file,
        });
        return false;
    };

    const handleRemove = () => {
        setData({
            ...data,
            image: '',
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        let fd = new FormData();
        fd.set('part_name', data.part_name);
        fd.set('part_number', data.part_number);
        fd.set('customer_id', data.customer_id);
        fd.set('customer_name', data.customer_name);
        fd.set('plan_id', data.plan_id);
        fd.set('plan_name', data.plan_name);
        fd.set('main_processes', JSON.stringify(data.main_processes));
        fd.set('line_processes', JSON.stringify(data.line_processes));
        // fd.set("sub_process", data.sub_process);
        fd.set('material', data.material);
        fd.set('model', data.model);
        fd.set('diameter', data.diameter);
        fd.set('thickness', data.thickness);
        // fd.set("main_process", data.main_process_name);
        fd.set('material_code', data.material_code);
        fd.set('line_internal', data.line_internal);
        fd.set('route_internal', data.route_internal);
        fd.set('panjang', data.panjang);
        fd.set('toleransi', data.toleransi);
        fd.set('panjang_raw_material', data.panjang_raw_material);
        fd.set('negara_asal', data.negara_asal);
        fd.set('category_part', data.category_part);

        fd.set('jenis_mesin', data.jenis_mesin);
        fd.set('nomer_mesin', data.nomer_mesin);
        fd.set('mesin_cutting', data.mesin_cutting);
        fd.set('nomer_memori', data.nomer_memori);
        fd.set('stopper', data.stopper);
        fd.set('bending_mesin', data.bending_mesin);
        fd.set('bending_jig', data.bending_jig);
        fd.set('packing', data.packing);
        fd.set('raw_material', data.raw_material);
        fd.set('qty_std', data.qty_std);
        fd.set('qty_int', data.qty_int);
        fd.set('qty_erp', data.qty_erp);
        fd.set('prefix', data.prefix);
        fd.set('infix', data.infix);
        fd.set('suffix', data.suffix);
        fd.append('image', data.image);
        fd.set('category_type', data.category_type.toUpperCase());
        fd.set('part_children', JSON.stringify(data.part_children));
        fd.set('part_parent', JSON.stringify(data.part_parent));
        setLoading(true);

        axios
            .post(`${url}/master-spcard/${match.params.id}`, fd, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                message.success(res.data.message);
                history.push('/master-spcard');
            })
            .catch((err) => {
                if (err.response) {
                    setErrors(err.response.data.errors);
                    message.error(err.response.data.message);
                }
                setLoading(false);
            });
    };

    const getDepartment = async (keyword) => {
        await axios
            .get(`${url}/master-area/list/departement`, {
                params: {
                    keyword,
                    limit: perpage,
                    page,
                    sortBy: `${sort.sort}:${sort.order}`,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setDepartments(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const getMainProcess = async (keyword) => {
        await axios
            .get(`${url}/line-process/listmain`, {
                params: {
                    keyword,
                    department: data.plan_name,
                    limit: perpage,
                    page,
                    sortBy: `${sort.sort}:${sort.order}`,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setMainProcess(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const getLineProcess = async (keyword) => {
        const listMainProcesses =
            data.main_processes.length > 0
                ? data.main_processes.map((item) => item.label)
                : [];
        if (listMainProcesses.length > 0) {
            await axios
                .get(`${url}/line-process/list`, {
                    params: {
                        department: data.plan_name,
                        mainProcesses: listMainProcesses,
                        keyword,
                        limit: perpage,
                        page,
                        sortBy: `${sort.sort}:${sort.order}`,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                .then((res) => {
                    setLineProcess(res.data.data);
                })
                .catch((err) => {
                    if (err.response) {
                        message.error(err.response.data.message);
                        if (err.response.status === 401) {
                            localStorage.removeItem('token');
                        }
                    }
                });
        }
    };

    const getPlan = async (keyword) => {
        await axios
            .get(`${url}/plan/list`, {
                params: {
                    keyword,
                    limit: perpage,
                    page,
                    sortBy: `${sort.sort}:${sort.order}`,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setPlan(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const getSpcards = async (keyword) => {
        await axios
            .get(`${url}/master-spcard`, {
                params: {
                    keyword,
                    perpage: 15,
                    page: 1,
                    columns: ['part_number', 'part_name'],
                    sortBy: `created_at:descend`,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setPartParents(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const getCustomer = async (keyword) => {
        await axios
            .get(`${url}/customer/list`, {
                params: {
                    keyword,
                    limit: perpage,
                    page,
                    sortBy: `${sort.sort}:${sort.order}`,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setCustomers(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const handleChangeSelect = (name, value) => {
        setErrors(null);
        if (value) {
            setData({
                ...data,

                [`${name}_id`]: value.value,
                [`${name}_name`]: value.label,
            });
        } else {
            setData({
                ...data,

                [`${name}_id`]: null,
                [`${name}_name`]: null,
            });
        }
    };

    const handleChangeSelectCategory = (name, value) => {
        setErrors(null);
        setData({
            ...data,
            [name]: value.value,
        });
    };

    const handleChangeSelectMultiple = (name, value) => {
        if (
            value &&
            value.length &&
            value.some((item) => item.value === 'all')
        ) {
            const mapData = (items, key) =>
                items.map((item) => ({
                    value: key === 'sub_process' ? item['_id'] : item[key],
                    label:
                        key === 'sub_process'
                            ? [item['sub_process'], ' -', ' ', item['name']]
                            : item[key],
                    key: key === 'sub_process' ? item['_id'] : item[key],
                }));

            let allData;

            switch (name) {
                case 'main_processes':
                    allData = mainProcess
                        ? mapData(mainProcess, 'main_process')
                        : [];
                    break;
                default:
                    allData = lineProcess
                        ? mapData(lineProcess, 'sub_process')
                        : [];
                    break;
            }
            setData({ ...data, [name]: allData });
        } else {
            setData({ ...data, [name]: value });
        }
    };

    return (
        <div>
            <ul className="breadcumb">
                <li>
                    <Link to="/master-spcard">
                        <LeftOutlined />
                        Master SP Card
                    </Link>
                </li>
                <li className="active">Edit Master SP Card</li>
            </ul>
            <div className="content">
                <form onSubmit={handleSave}>
                    <Col span={12}></Col>
                    <Row gutter={20}>
                        <Col md={8} xs={24}>
                            <div className="form-group">
                                <div className="form-group">
                                    <label className="form-label">
                                        Part Number{' '}
                                        <span className="error-text">*</span>
                                    </label>
                                    <Input
                                        value={data.part_number}
                                        className={
                                            errors && errors.part_number
                                                ? 'is-error'
                                                : ''
                                        }
                                        onChange={(e) =>
                                            handleChange(
                                                'part_number',
                                                e.target.value,
                                            )
                                        }
                                    />
                                    {errors && errors.part_number && (
                                        <span className="error-text">
                                            {errors.part_number[0]}
                                        </span>
                                    )}
                                </div>
                                <label className="form-label">
                                    Part Name{' '}
                                    <span className="error-text">*</span>
                                </label>
                                <Input
                                    value={data.part_name}
                                    className={
                                        errors && errors.part_name
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'part_name',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.part_name && (
                                    <span className="error-text">
                                        {errors.part_name[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                Customer <span className="error-text">*</span>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelect('customer', value)
                                    }
                                    onFocus={() => getCustomer('')}
                                    showSearch
                                    onSearch={(value) => getCustomer(value)}
                                    filterOption={false}
                                    value={{
                                        key: data.customer_id,
                                        label: data.customer_name,
                                    }}
                                >
                                    {customers &&
                                        customers.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept._id}
                                                    key={dept._id}
                                                >
                                                    {dept.name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {errors && errors.customer && (
                                    <span className="error-text">
                                        {errors.customer[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                Department <span className="error-text">*</span>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelect('plan', value)
                                    }
                                    onFocus={() => getPlan('')}
                                    showSearch
                                    onSearch={(value) => getPlan(value)}
                                    filterOption={false}
                                    value={{
                                        key: data.plan_id,
                                        label: data.plan_name,
                                    }}
                                >
                                    {plans &&
                                        plans.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept._id}
                                                    key={dept._id}
                                                >
                                                    {dept.name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {errors && errors.plan && (
                                    <span className="error-text">
                                        {errors.plan[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">Model</label>
                                <Input
                                    value={data.model}
                                    className={
                                        errors && errors.model ? 'is-error' : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('model', e.target.value)
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Material
                                    <span className="error-text">*</span>
                                </label>
                                <Input
                                    value={data.material}
                                    className={
                                        errors && errors.material
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('material', e.target.value)
                                    }
                                />
                                {errors && errors.material && (
                                    <span className="error-text">
                                        {errors.material[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Material Code{' '}
                                    <span className="error-text">*</span>
                                </label>
                                <Input
                                    value={data.material_code}
                                    className={
                                        errors && errors.material_code
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'material_code',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.material_code && (
                                    <span className="error-text">
                                        {errors.material_code[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Diameter{' '}
                                    <span className="error-text">*</span>
                                </label>
                                <Input
                                    value={data.diameter}
                                    className={
                                        errors && errors.diameter
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('diameter', e.target.value)
                                    }
                                />
                                {errors && errors.diameter && (
                                    <span className="error-text">
                                        {errors.diameter[0]}
                                    </span>
                                )}
                            </div>

                            <div className="form-group">
                                <label className="form-label">
                                    Thickness{' '}
                                    <span className="error-text">*</span>
                                </label>
                                <Input
                                    value={data.thickness}
                                    className={
                                        errors && errors.thickness
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'thickness',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.thickness && (
                                    <span className="error-text">
                                        {errors.thickness[0]}
                                    </span>
                                )}
                            </div>

                            <div className="form-group">
                                <label className="form-label">
                                    Panjang{' '}
                                    <span className="error-text">*</span>
                                </label>
                                <Input
                                    value={data.panjang}
                                    className={
                                        errors && errors.panjang
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('panjang', e.target.value)
                                    }
                                />
                                {errors && errors.panjang && (
                                    <span className="error-text">
                                        {errors.panjang[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">Toleransi </label>
                                <Input
                                    value={data.toleransi}
                                    className={
                                        errors && errors.toleransi
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'toleransi',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.toleransi && (
                                    <span className="error-text">
                                        {errors.toleransi[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Bending Mesin{' '}
                                </label>
                                <Input
                                    value={data.bending_mesin}
                                    className={
                                        errors && errors.bending_mesin
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'bending_mesin',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.bending_mesin && (
                                    <span className="error-text">
                                        {errors.bending_mesin[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Bending Jig{' '}
                                </label>
                                <Input
                                    value={data.bending_jig}
                                    className={
                                        errors && errors.bending_jig
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'bending_jig',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.bending_jig && (
                                    <span className="error-text">
                                        {errors.bending_jig[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                SPCARD Category{' '}
                                <span className="error-text">*</span>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelectCategory(
                                            'category_type',
                                            value,
                                        )
                                    }
                                    value={{key: data.category_type, label:data.category_type}}
                                    filterOption={false}
                                >
                                    {categoryType.map((category) => {
                                        return (
                                            <Option
                                                value={category.toUpperCase()}
                                                key={category.toUpperCase()}
                                            >
                                                {category.toUpperCase()}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                {errors && errors.category_type && (
                                    <span className="error-text">
                                        {errors.category_type[0]}
                                    </span>
                                )}
                            </div>
                        </Col>
                        <Col md={8} xs={24}>
                            <div className="form-group">
                                <label className="form-label">
                                    Qty Packing / SNP
                                    <span className="error-text">*</span>
                                </label>
                                <Input
                                    value={data.packing}
                                    className={
                                        errors && errors.packing
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('packing', e.target.value)
                                    }
                                />
                                {errors && errors.packing && (
                                    <span className="error-text">
                                        {errors.packing[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Raw Material{' '}
                                    <span className="error-text">*</span>
                                </label>
                                <Input
                                    value={data.raw_material}
                                    className={
                                        errors && errors.raw_material
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'raw_material',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.raw_material && (
                                    <span className="error-text">
                                        {errors.raw_material[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Panjang Raw Material{' '}
                                </label>
                                <Input
                                    value={data.panjang_raw_material}
                                    className={
                                        errors && errors.panjang_raw_material
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'panjang_raw_material',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.panjang_raw_material && (
                                    <span className="error-text">
                                        {errors.panjang_raw_material[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Negara Asal{' '}
                                </label>
                                <Input
                                    value={data.negara_asal}
                                    className={
                                        errors && errors.negara_asal
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'negara_asal',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.negara_asal && (
                                    <span className="error-text">
                                        {errors.negara_asal[0]}
                                    </span>
                                )}
                            </div>

                            <div className="form-group">
                                <label className="form-label">
                                    Category Part{' '}
                                </label>
                                <Input
                                    value={data.category_part}
                                    className={
                                        errors && errors.category_part
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'category_part',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.category_part && (
                                    <span className="error-text">
                                        {errors.category_part[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Main Process{' '}
                                    <span className="error-text">*</span>
                                </label>
                                {/* <Input
                  value={data.main_process}
                  className={errors && errors.main_process ? "is-error" : ""}
                  onChange={(e) => handleChange("main_process", e.target.value)}
                />
                {errors && errors.main_process && (
                  <span className="error-text">{errors.main_process[0]}</span>
                )} */}
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelectMultiple(
                                            'main_processes',
                                            value,
                                        )
                                    }
                                    onFocus={() => getMainProcess('')}
                                    showSearch
                                    onSearch={(value) => getMainProcess(value)}
                                    filterOption={false}
                                    mode={'multiple'}
                                    value={data.main_processes}
                                >
                                    <Option key="all" value="all">
                                        SELECT ALL
                                    </Option>
                                    {mainProcess &&
                                        mainProcess.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept.main_process}
                                                    key={dept.main_process}
                                                >
                                                    {dept.main_process}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {errors && errors.customer && (
                                    <span className="error-text">
                                        {errors.customer[0]}
                                    </span>
                                )}
                            </div>

                            <div className="form-group">
                                Note : Routing (Sub Process)
                                <span className="error-text"> *</span>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelectMultiple(
                                            'line_processes',
                                            value,
                                        )
                                    }
                                    onFocus={() => getLineProcess('')}
                                    showSearch
                                    onSearch={(value) => getLineProcess(value)}
                                    filterOption={false}
                                    mode={'multiple'}
                                    value={data.line_processes}
                                >
                                    <Option key="all" value="all">
                                        SELECT ALL
                                    </Option>
                                    {lineProcess &&
                                        lineProcess.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept._id}
                                                    key={dept._id}
                                                >
                                                    {dept.sub_process} -{' '}
                                                    {dept.description}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {errors && errors.line_process && (
                                    <span className="error-text">
                                        {errors.line_process[0]}
                                    </span>
                                )}
                            </div>

                            <div className="form-group">
                                <label className="form-label">Prefix</label>
                                <Input
                                    value={data.prefix}
                                    className={
                                        errors && errors.prefix
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('prefix', e.target.value)
                                    }
                                />
                                {errors && errors.prefix && (
                                    <span className="error-text">
                                        {errors.prefix[0]}
                                    </span>
                                )}
                            </div>

                            <div className="form-group">
                                <label className="form-label">Infix</label>
                                <Input
                                    value={data.infix}
                                    className={
                                        errors && errors.infix ? 'is-error' : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('infix', e.target.value)
                                    }
                                />
                                {errors && errors.infix && (
                                    <span className="error-text">
                                        {errors.infix[0]}
                                    </span>
                                )}
                            </div>

                            <div className="form-group">
                                <label className="form-label">Suffix</label>
                                <Input
                                    value={data.suffix}
                                    className={
                                        errors && errors.suffix
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('suffix', e.target.value)
                                    }
                                />
                                {errors && errors.suffix && (
                                    <span className="error-text">
                                        {errors.suffix[0]}
                                    </span>
                                )}
                            </div>

                            {/* <div className="form-group">
                Main Process Name <span className="error-text">*</span>
                <Select
                 
                  allowClear
                  style={{ width: '100%' }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelectMultiple("main_processes", value)
                  }
                  onFocus={() => getMainProcess("")}
                  showSearch
                  onSearch={(value) => getMainProcess(value)}
                  filterOption={false}
                  mode={"multiple"}
                  value={data.main_processes}
    
                >
                  {mainProcess &&
                    mainProcess.map((dept) => {
                      return (
                        <Option value={dept._id} key={dept._id}>
                          {dept.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.main_process && (
                  <span className="error-text">{errors.main_process[0]}</span>
                )}
              </div> */}

                            {/* <div className="form-group">
                <label className="form-label">
                  Sub Process <span className="error-text">*</span>
                </label>
                <Input
                 
                  value={data.sub_process}
                  className={errors && errors.sub_process ? "is-error" : ""}
                  onChange={(e) => handleChange("sub_process", e.target.value)}
                />
                {errors && errors.sub_process && (
                  <span className="error-text">{errors.sub_process[0]}</span>
                )}
              </div> */}

                            <div className="form-group">
                                <label className="form-label">
                                    Line Internal{' '}
                                </label>
                                <Input
                                    value={data.line_internal}
                                    className={
                                        errors && errors.line_internal
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'line_internal',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.line_internal && (
                                    <span className="error-text">
                                        {errors.line_internal[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Route Internal{' '}
                                </label>
                                <Input
                                    value={data.route_internal}
                                    className={
                                        errors && errors.route_internal
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'route_internal',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.route_internal && (
                                    <span className="error-text">
                                        {errors.route_internal[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                Part Parent
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelectMultiple(
                                            'part_parent',
                                            value,
                                        )
                                    }
                                    onFocus={() => getSpcards('')}
                                    showSearch
                                    onSearch={(value) => getSpcards(value)}
                                    filterOption={false}
                                    mode={'multiple'}
                                    value={data.part_parent}
                                >
                                    {partParents &&
                                        partParents.map((part) => {
                                            return (
                                                <Option
                                                    value={part._id}
                                                    key={part._id}
                                                >
                                                    {part.part_number} -{' '}
                                                    {part.part_name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {errors && errors.part_parent && (
                                    <span className="error-text">
                                        {errors.part_parent[0]}
                                    </span>
                                )}
                            </div>

                            <div className="form-group">
                                <label className="form-label">
                                    Part Children
                                </label>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelectMultiple(
                                            'part_children',
                                            value,
                                        )
                                    }
                                    onFocus={() => getSpcards('')}
                                    showSearch
                                    onSearch={(value) => getSpcards(value)}
                                    filterOption={false}
                                    mode={'multiple'}
                                    value={data.part_children}
                                >
                                    {partParents &&
                                        partParents.map((part) => {
                                            return (
                                                <Option
                                                    value={part._id}
                                                    key={part._id}
                                                >
                                                    {part.part_number} -{' '}
                                                    {part.part_name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {errors && errors.line_process && (
                                    <span className="error-text">
                                        {errors.line_process[0]}
                                    </span>
                                )}
                            </div>
                        </Col>

                        <Col md={8} xs={24}>
                            <div className="form-group">
                                <label className="form-label">
                                    Qty Standard{' '}
                                    <span className="error-text">*</span>
                                </label>
                                <Input
                                    type="number"
                                    value={data.qty_std}
                                    className={
                                        errors && errors.qty_std
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('qty_std', e.target.value)
                                    }
                                />
                                {errors && errors.qty_std && (
                                    <span className="error-text">
                                        {errors.qty_std[0]}
                                    </span>
                                )}
                            </div>
                            {/* <div className="form-group">
                                <label className="form-label">Qty Int </label>
                                <Input
                                    type="number"
                                    value={data.qty_int}
                                    className={
                                        errors && errors.qty_int
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('qty_int', e.target.value)
                                    }
                                />
                                {errors && errors.qty_int && (
                                    <span className="error-text">
                                        {errors.qty_int[0]}
                                    </span>
                                )}
                            </div> */}
                            <div className="form-group">
                                <label className="form-label">
                                    Qty Deburing
                                </label>
                                <Input
                                    type="number"
                                    value={data.qty_erp}
                                    className={
                                        errors && errors.qty_erp
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('qty_erp', e.target.value)
                                    }
                                />
                                {errors && errors.qty_erp && (
                                    <span className="error-text">
                                        {errors.qty_erp[0]}
                                    </span>
                                )}
                            </div>

                            <div className="form-group">
                                <label className="form-label">
                                    Jenis Mesin{' '}
                                    <span className="error-text">*</span>
                                </label>
                                <Input
                                    value={data.jenis_mesin}
                                    className={
                                        errors && errors.jenis_mesin
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'jenis_mesin',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.jenis_mesin && (
                                    <span className="error-text">
                                        {errors.jenis_mesin[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Nomor Mesin{' '}
                                    <span className="error-text">*</span>
                                </label>
                                <Input
                                    value={data.nomer_mesin}
                                    className={
                                        errors && errors.nomer_mesin
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'nomer_mesin',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.nomer_mesin && (
                                    <span className="error-text">
                                        {errors.nomer_mesin[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Mesin Cutting
                                </label>
                                <Input
                                    value={data.mesin_cutting}
                                    className={
                                        errors && errors.mesin_cutting
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'mesin_cutting',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.mesin_cutting && (
                                    <span className="error-text">
                                        {errors.mesin_cutting[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Nomor Memori{' '}
                                    <span className="error-text">*</span>
                                </label>
                                <Input
                                    value={data.nomer_memori}
                                    className={
                                        errors && errors.nomer_memori
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'nomer_memori',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.nomer_memori && (
                                    <span className="error-text">
                                        {errors.nomer_memori[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">Stopper </label>
                                <Input
                                    value={data.stopper}
                                    className={
                                        errors && errors.stopper
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('stopper', e.target.value)
                                    }
                                />
                                {errors && errors.stopper && (
                                    <span className="error-text">
                                        {errors.stopper[0]}
                                    </span>
                                )}
                            </div>

                            <div
                                style={{
                                    justifyContent: 'left',
                                }}
                            >
                                <div className="mb-20">
                                    <Image
                                        src={
                                            data.image_url
                                                ? data.image_url
                                                : data.image
                                                  ? URL.createObjectURL(
                                                        data.image,
                                                    )
                                                  : `${storage_path}${data.part_number}.PNG`
                                        }
                                        width={400}
                                        height={300}
                                    />
                                </div>
                                <Upload
                                    onRemove={handleRemove}
                                    beforeUpload={handleBeforeUpload}
                                    fileList={[]}
                                    accept="image/jpg, image/png"
                                >
                                    {data.image ? (
                                        <Button
                                            type="danger"
                                            onClick={handleRemove}
                                        >
                                            <i className="mdi mdi-close mr-5" />
                                            Delete Foto
                                        </Button>
                                    ) : (
                                        <Button
                                            type="primary"
                                            style={{
                                                backgroundColor: '#1b2086',
                                                marginBottom: 10,
                                            }}
                                        >
                                            <i className="mdi mdi-upload mr-5" />
                                            Upload Foto
                                        </Button>
                                    )}
                                </Upload>
                            </div>
                            <div
                                className="form-group"
                                style={{ marginTop: 21 }}
                            >
                                <Button
                                    type="primary"
                                    onClick={handleSave}
                                    loading={loading}
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                    }}
                                >
                                    {/* <i className="mdi mdi-content-save mr-5" />  */}
                                    Submit
                                </Button>

                                <Button
                                    htmlType="reset"
                                    type="primary"
                                    onClick={handleReset}
                                    // loading={loading}
                                    style={{
                                        backgroundColor: '#1b2086',
                                        marginLeft: 10,
                                        borderColor: '#1b2086',
                                    }}
                                >
                                    {/* <i className="mdi mdi-content-save mr-5" /> */}
                                    Reset
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    );
}

export default EditMasterSpCard;
