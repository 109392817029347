import {
    Button,
    Card,
    Col,
    Tag,
    Dropdown,
    Input,
    Menu,
    Popconfirm,
    Row,
    Space,
    Table,
    message,
    Switch,
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
    DeleteOutlined,
    PlusOutlined,
    EyeOutlined,
    FileExcelOutlined,
    BarcodeOutlined,
    PrinterOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import Axios from '../../../config/axios';
import axios from 'axios';
import Import from './components/Import';
import fileDownload from 'js-file-download';
import Can from '../../../components/Can';
import { url } from '../../../constant/url';
import GenerateSPCARDHistory from './components/GenerateSPCARDHistory';
import PrintLabel from './components/PrintLabel';
import FilterGenerateSPCard from './components/FilterGenerateSPCard';
import ExportGenerateSPCard from './components/ExportGenerateSPCard';
import './Custom.css';
function GenerateSPCARD({ history }) {
    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);

    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState([
        {
            order: 'ascend',
            field: 'no_mesin',
        },
        {
            order: 'ascend',
            field: 'diameter',
        },
        {
            order: 'descend',
            field: 'panjang',
        },
        {
            order: 'ascend',
            field: 'material',
        },
    ]);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [isImport, setIsImport] = useState(false);
    const [error, setError] = useState(null);
    const [spcard, setSpcard] = useState([]);
    const [isPrintVisible, setIsPrintVisible] = useState(false);
    const [printId, setPrintId] = useState(null);
    const [printRemaining, setPrintRemaining] = useState(null);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [isExportVisible, setIsExportVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showGenerated, setShowGenerated] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
    });
    const [dateRangeValue, setDateRangeValue] = useState(null);

    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded ? [record._id] : [];
        setExpandedRowKeys(newExpandedRowKeys);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        if (!Array.isArray(sorter)) {
            setSort([sorter]);
        } else {
            setSort(sorter);
        }
    };

    useEffect(() => {
        getData();
    }, [keyword, page, perpage, sort, showGenerated, dateRange]);

    const handleDateRange = (dates) => {
        if (dates && dates.length === 2) {
            setDateRange({
                startDate: dates[0],
                endDate: dates[1],
            });
        } else {
            setDateRange({
                startDate: null,
                endDate: null,
            });
        }

        setDateRangeValue(dates);
    };

    const getData = (filters = null) => {
        setLoading(true);

        const formattedStartDate = dateRange.startDate
            ? moment(dateRange.startDate).format('YYYY-MM-DD')
            : null;
        const formattedEndDate = dateRange.endDate
            ? moment(dateRange.endDate).format('YYYY-MM-DD')
            : null;

        console.log(formattedStartDate, formattedEndDate);

        Axios.get('/generate-spcard', {
            params: {
                keyword,
                filters,
                perpage: perpage,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                showGenerated,
                page,
                sort,
            },
        })
            .then((res) => {
                setSpcard(res.data.data);
                setTotal(res.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onSelectRowChange = (newSelectedRowKeys, selectedRows) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const handleDownloadTemplate = () => {
        Axios.get('generate-spcard/import/template', { responseType: 'blob' })
            .then((res) => {
                fileDownload(res.data, 'template_generate_sp_card.xlsx');
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    const handlePrint = (id, printRem) => {
        setIsPrintVisible(true);
        setPrintId(id);
        setPrintRemaining(printRem);
    };

    const handleGenerate = (id) => {
        setLoading(true);
        Axios.get(`/generate-spcard/${id}/generate`)
            .then(({ data }) => {
                message.success(data.message);
                getData();
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleReprint = (id) => {
        setLoading(true);
        Axios.get(`/generate-spcard/${id}/reprint`, {
            responseType: 'blob',
        })
            .then((res) => {
                var blob = new Blob([res.data], { type: 'application/pdf' });
                var blobURL = URL.createObjectURL(blob);
                window.open(blobURL);
                getData();
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                    if (error.response.status === 422) {
                        setError(error.response.data.errors);
                    }
                    if (error.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = (id) => {
        setLoading(true);
        Axios.delete(`/generate-spcard/${id}`)
            .then(({ data }) => {
                message.success(data.message);
                getData();
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleGenerateSelected = () => {
        setLoading(true);
        Axios.post(`/generate-spcard/multiple/generate`, {
            spcards: selectedRowKeys,
        })
            .then(({ data }) => {
                message.success(data.message);
                getData();
            })
            .catch((error) => {
                if (error.response) {
                    let errorMessage = '';
                    if (typeof error.response.data.message === 'object') {
                        const { spcard } = error.response.data.message;
                        errorMessage = spcard[0];
                    } else {
                        errorMessage = error.response.data.message;
                    }
                    message.error(errorMessage);
                }
            })
            .finally(() => {
                setLoading(false);
                setSelectedRowKeys([]);
            });
    };

    const rowClassName = (record) => {
        return record.is_generate === 1 ? 'row-generate' : '';
    };

    const handlePreview = (id) => {
        setLoading(true);
        axios
            .get(`${url}/generate-spcard/${id}/preview`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                responseType: 'blob',
            })
            .then((res) => {
                var blob = new Blob([res.data], { type: 'application/pdf' });
                var blobURL = URL.createObjectURL(blob);
                window.open(blobURL);
                getData();
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 422) {
                        setError(err.response.data.errors);
                    }
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                } else {
                    message.error('Backend not yet Started');
                }
                setLoading(false);
            });
    };

    const columns = [
        {
            title: 'Part Number',
            dataIndex: 'part_number',
            key: 'part_number',
            sorter: {
                multiple: 5,
            },
        },
        {
            title: 'Production Date',
            dataIndex: 'production_date',
            key: 'production_date',
            sorter: true,
            render: (date) => {
                return moment.utc(date).format('YYYY-MM-DD');
            },
        },
        {
            title: 'Qty Std',
            dataIndex: 'qty_std',
            key: 'qty_std',
            sorter: {
                multiple: 10,
            },
        },
        {
            title: 'Qty ERP ( SNP )',
            dataIndex: 'qty_erp',
            key: 'qty_erp',
            sorter: {
                multiple: 10,
            },
        },
        {
            title: 'Qty Planning',
            dataIndex: 'qty_planning',
            key: 'qty_planning',
            sorter: {
                multiple: 11,
            },
        },
        {
            title: 'Departemen',
            dataIndex: 'departemen',
            key: 'departemen',
            sorter: {
                multiple: 9,
            },
        },
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            key: 'part_name',
            sorter: {
                multiple: 6,
            },
        },
        {
            title: 'Customer',
            dataIndex: 'customer_name',
            key: 'customer_name',
            sorter: true,
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            sorter: true,
        },
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',
            sorter: {
                multiple: 3,
            },
        },
        {
            title: 'Diameter',
            dataIndex: 'diameter',
            key: 'diameter',
            sorter: {
                multiple: 2,
            },
        },
        {
            title: 'Thickness',
            dataIndex: 'thickness',
            key: 'thickness',
            sorter: true,
        },
        {
            title: 'Raw Material',
            dataIndex: 'raw_material',
            key: 'raw_material',
            sorter: true,
            render: (_, record) =>
                record.raw_material
                    ? record.raw_material
                    : record.spcard && record.spcard.raw_material,
        },
        {
            title: 'Panjang',
            dataIndex: 'panjang',
            key: 'panjang',
            sorter: {
                multiple: 4,
            },
        },
        {
            title: 'Jenis Mesin',
            dataIndex: 'jenis_mesin',
            key: 'jenis_mesin',
            sorter: {
                multiple: 7,
            },
        },
        {
            title: 'No. Mesin',
            dataIndex: 'no_mesin',
            key: 'no_mesin',
            sorter: {
                multiple: 1,
            },
        },
        {
            title: 'Mesin Cutting',
            dataIndex: 'mesin_cutting',
            key: 'mesin_cutting',
            render: (_, record) =>
                record.mesin_cutting
                    ? record.mesin_cutting
                    : record.spcard && record.spcard.mesin_cutting,
        },
        {
            title: 'No. Memori',
            dataIndex: 'no_memori',
            key: 'no_memori',
            sorter: true,
        },

        {
            title: 'Total Label',
            dataIndex: 'total_label',
            key: 'total_label',
            sorter: true,
        },
        {
            title: 'Print Total',
            dataIndex: 'print_total',
            key: 'print_total',
            sorter: true,
        },
        {
            title: 'Print Remaining',
            dataIndex: 'print_remaining',
            key: 'print_remaining',
            sorter: true,
        },

        {
            title: 'Reprint Status',
            dataIndex: 'total_reprint',
            key: 'total_reprint',
            render: (_, record) =>
                record.total_reprint > 0 && (
                    <Tag color="orange">Reprint {record.total_reprint}x</Tag>
                ),
        },
        {
            title: 'Re Print By',
            dataIndex: 'reprint_by',
            key: 'reprint_by',
        },
        {
            title: '',
            dataIndex: '_id',
            key: 'id',
            sorter: false,
            fixed: 'right',
            width: 50,
            render: (_, record) => {
                return (
                    <>
                        <Dropdown
                            className="pointer"
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key="1"
                                        onClick={() =>
                                            handlePreview(record._id)
                                        }
                                    >
                                        <EyeOutlined /> Preview
                                    </Menu.Item>

                                    {record.total_histories > 0 &&
                                        record.total_histories <
                                            record.print_total && (
                                            <Menu.Item
                                                key="1"
                                                onClick={() =>
                                                    handlePrint(
                                                        record._id,
                                                        record.print_remaining,
                                                    )
                                                }
                                            >
                                                <PrinterOutlined /> Print
                                            </Menu.Item>
                                        )}

                                    {record.total_histories <
                                        record.total_label && (
                                        <Menu.Item
                                            key="2"
                                            onClick={() =>
                                                handleGenerate(record._id)
                                            }
                                        >
                                            <BarcodeOutlined /> Generate
                                        </Menu.Item>
                                    )}
                                    {record.print_remaining == 0 && (
                                        <Menu.Item
                                            key="3"
                                            onClick={() =>
                                                handleReprint(record._id)
                                            }
                                        >
                                            <PrinterOutlined /> Reprint
                                        </Menu.Item>
                                    )}
                                    <Menu.Item key="4" danger>
                                        <Popconfirm
                                            title="Are you sure delete this generate SP Card?"
                                            onConfirm={() =>
                                                handleDelete(record._id)
                                            }
                                            okText="OK"
                                            okType="danger"
                                        >
                                            <DeleteOutlined /> Delete
                                        </Popconfirm>
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={['click']}
                        >
                            <span
                                className="pointer"
                                onClick={(e) => e.preventDefault()}
                            >
                                <i className="mdi mdi-dots-vertical" />
                            </span>
                        </Dropdown>
                    </>
                );
            },
        },
    ];

    return (
        <div>
            <ul className="breadcumb">
                <li className="active">Generate S.P.CARD CUT</li>
            </ul>
            <Card>
                <Row justify="space-between" gutter={[16, 16]}>
                    <Col>
                        <Space>
                            {/* <Button
                                type="primary"
                                style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}>
                                <PlusOutlined />
                                Generate Bulk S.P.CARD
                            </Button> */}
                            <Can accessTo="Add Generate S.P.CARD">
                                <Link to="/generate-spcard/create">
                                    <Button
                                        type="primary"
                                        style={{
                                            backgroundColor: '#1b2086',
                                            borderColor: '#1b2086',
                                        }}
                                    >
                                        <PlusOutlined />
                                        Add
                                    </Button>
                                </Link>
                            </Can>

                            <Can accessTo="Import Generate S.P. CARD">
                                <Import
                                    isVisible={isImport}
                                    setIsVisible={setIsImport}
                                    onFinish={() => getData()}
                                />
                            </Can>

                            <Can accessTo="Export Generate S.P. CARD">
                                <ExportGenerateSPCard
                                    isVisible={isExportVisible}
                                    setIsVisible={setIsExportVisible}
                                />
                            </Can>
                            {/* <Button
                                type="primary"
                                style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}>
                                <PlusOutlined />
                                Export
                            </Button> */}
                            <Can accessTo="Download Template Generate S.P. CARD">
                                <Button
                                    onClick={handleDownloadTemplate}
                                    type="primary"
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                    }}
                                >
                                    <FileExcelOutlined />
                                    Download Template
                                </Button>
                            </Can>

                            <Can accessTo="Generate Generate S.P.CARD">
                                <Button
                                    type="primary"
                                    disabled={selectedRowKeys.length <= 0}
                                    onClick={() => handleGenerateSelected()}
                                >
                                    <BarcodeOutlined />
                                    Generate
                                </Button>
                            </Can>
                            <Space>
                                <span>Generated</span>
                                <Switch
                                    checkedChildren="Hide"
                                    unCheckedChildren="Show"
                                    size="default"
                                    onChange={() =>
                                        setShowGenerated(!showGenerated)
                                    }
                                />
                            </Space>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            {/* <Can accessTo="Filter Generate Spcard">
                        <DatePicker.RangePicker style={{ width: "100%" }} onCalendarChange={handleDateRange} value={dateRangeValue}/>
                        </Can> */}
                            <FilterGenerateSPCard onFinish={getData} />
                            <Input.Search
                                allowClear="true"
                                onSearch={(value) => {
                                    setPage(1);
                                    setKeyword(value);
                                }}
                                placeholder="Search..."
                                inputStyle={{
                                    whiteSpace: 'nowrap',
                                    overflowX: 'auto',
                                }}
                                style={{width: "25rem"}}
                            />
                        </Space>
                    </Col>
                </Row>
                <PrintLabel
                    id={printId}
                    isVisible={isPrintVisible}
                    setIsVisible={setIsPrintVisible}
                    onFinish={getData}
                    printRemaining={printRemaining}
                />
                <Row className="mt-40">
                    <Col xs={24}>
                        <Table
                            scroll={{ x: 'max-content' }}
                            rowSelection={{
                                preserveSelectedRowKeys: false,
                                selectedRowKeys,
                                onChange: onSelectRowChange,
                                getCheckboxProps: (record) => ({
                                    disabled: record.is_generate === 1,
                                    className:
                                        record.is_generate === 1
                                            ? 'row-generate'
                                            : '',
                                }),
                            }}
                            columns={columns}
                            dataSource={spcard}
                            pagination={{
                                current: page,
                                total,
                                showSizeChanger: true,
                                pageSizeOptions: [
                                    '10',
                                    '20',
                                    '50',
                                    '100',
                                    total.toString(),
                                ],
                            }}
                            loading={loading}
                            rowKey={(data) => data._id}
                            onChange={handleTableChange}
                            expandable={{
                                expandedRowKeys: expandedRowKeys,
                                onExpand: handleExpand,
                                expandedRowRender: (record) => (
                                    <GenerateSPCARDHistory
                                        key={`history-${record._id}`}
                                        id={record._id}
                                        rows={expandedRowKeys}
                                    />
                                ),
                            }}
                            rowClassName={rowClassName}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    );
}

export default GenerateSPCARD;
