import { Button, Col, Input, message, Row, Avatar, Upload, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Ng.css';
import axios from 'axios';
import { url } from '../../constant/url';
import profile from '../../assets/profile.jpg';
import { LeftOutlined } from '@ant-design/icons';
import Ng from './Ng';
import Axios from '../../config/axios';

const { Option } = Select;

function EditNg({ history, match }) {
    const role = localStorage.getItem('data');
    const roleObject = JSON.parse(role);

    const roleName = roleObject.role_name;

    const [data, setData] = useState({
        name: '',
        line_process_id: '',
        line_process_name: '',
        main_process_id: '',
        main_process_name: '',
        line_processes: [],
        main_processes: [],
        ng_prosess_names: [],
        plan_id: '',
        plan_name: '',
    });

    const [page] = useState(1);
    const [perpage] = useState(10);
    const [sort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [details, setDetails] = useState([]);
    const [mainProcess, setMainProcess] = useState([]);
    const [lineProcess, setLineProcess] = useState([]);
    const [plans, setPlan] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        Axios.get(`/master-ng/${match.params.id}`)
            .then((res) => {
                const data = res.data.data;
                setData({
                    ...data,

                    name: data.name,
                    plan_id: data.plan_id,
                    plan_name: data.plan_name,
                    main_process_id: data.main_process ?? data.main_processes,
                    main_process_name: data.main_process ?? data.main_processes,

                    main_processes: data.tbm_ng_line_detail.map((x) => {
                        return {
                            key: x.line_process_id,
                            value: x.line_process_id,
                            label: x.main_process_name,
                        };
                    }),
                    ng_prosess_names: data.tbm_ng_detail.map((item, index) => ({
                        ...item,
                        id: index,
                    })),
                });
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    const handleChange = (name, value) => {
        setErrors({
            ...errors,
            [name]: '',
        });

        setData({
            ...data,
            [name]: value,
        });
    };

    const handleReset = () => {
        setData({
            name: '',
            line_process_id: '',
            line_process_name: '',
            main_process_id: '',
            main_process_name: '',
            line_processes: [],
            main_processes: [],
            ng_prosess_names: [],
            plan_id: '',
            plan_name: '',
        });
    };

    const handleChangeDetails = (id, value) => {
        const index = data.ng_prosess_names.findIndex((item) => item.id === id);

        if (index !== -1) {
            setData((prevData) => ({
                ...prevData,
                ng_prosess_names: [
                    ...prevData.ng_prosess_names.slice(0, index),
                    { ...prevData.ng_prosess_names[index], ng_name: value },
                    ...prevData.ng_prosess_names.slice(index + 1),
                ],
            }));
        }
    };

    const handleAddRow = () => {
        setData((prevData) => ({
            ...prevData,
            ng_prosess_names: [
                ...prevData.ng_prosess_names,
                { id: prevData.ng_prosess_names.length, ng_name: '' }, // Tambah objek baru dengan ID unik
            ],
        }));
    };

    const handleDeleteRow = (id) => {
        setData((prevData) => ({
            ...prevData,
            ng_prosess_names: prevData.ng_prosess_names
                .filter((item) => item.id !== id)
                .map((item, index) => ({ ...item, id: index })), // Perbarui ID setelah penghapusan
        }));
    };

    const handleSave = (e) => {
        // console.log(data.email);
        e.preventDefault();
        let fd = new FormData();

        fd.set('name', data.name);

        fd.set('plan_id', data.plan_id);
        fd.set('plan_name', data.plan_name);
        // fd.set("main_processes", JSON.stringify(data.main_processes));
        fd.set('main_process', data.main_process_name);
        fd.set('ng_prosess_names', JSON.stringify(data.ng_prosess_names));
        setLoading(true);
        Axios.post(`/master-ng/${match.params.id}`, fd)
            .then((res) => {
                message.success(res.data.message);
                history.push('/master-ng');
            })
            .catch((err) => {
                if (err.response) {
                    setErrors(err.response.data.errors);
                    message.error(err.response.data.message);
                }
            })
            .finally(() => setLoading(false));
    };

    const getPlan = async (keyword) => {
        await Axios.get(`/master-area/list/departement`, {
            params: {
                keyword,
                limit: perpage,
                page,
                sortBy: `${sort.sort}:${sort.order}`,
            },
        })
            .then((res) => {
                setPlan(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    const handleChangeSelect = (name, value) => {
        setErrors(null);
        if (value) {
            setData({
                ...data,
                line_processes: [],
                [`${name}_id`]: value.value,
                [`${name}_name`]: value.label,
            });
        } else {
            setData({
                ...data,

                line_processes: [],
            });
        }
    };
    const handleChangeSelectMultiple = (name, value) => {
        setData({
            ...data,
            [name]: value,
        });
    };

    const getMainProcess = (keyword) => {
        Axios.get(`${url}/line-process/listmain`, {
            params: {
                keyword,
                limit: perpage,
                department: data.plan_name,
                page,
                sortBy: `${sort.sort}:${sort.order}`,
            },
        })
            .then((res) => {
                setMainProcess(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    return (
        <div>
            <ul className="breadcumb">
                <li>
                    <Link to="/master-ng">
                        <LeftOutlined />
                        Master Ng
                    </Link>
                </li>
                <li className="active">Edit Master Ng</li>
            </ul>
            <div className="content">
                <form onSubmit={handleSave}>
                    <Col span={12}></Col>
                    <Row gutter={20}>
                        <Col md={8} xs={24}>
                            <div className="form-group">
                                <label className="form-label">
                                    Name <span className="error-text">*</span>
                                </label>
                                <Input
                                    className={
                                        errors && errors.name ? 'is-error' : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('name', e.target.value)
                                    }
                                    value={data.name}
                                />
                                {errors && errors.name && (
                                    <span className="error-text">
                                        {errors.name[0]}
                                    </span>
                                )}
                            </div>

                            <div className="form-group">
                                Department <span className="error-text">*</span>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelect('plan', value)
                                    }
                                    onFocus={() => getPlan('')}
                                    showSearch
                                    onSearch={(value) => getPlan(value)}
                                    filterOption={false}
                                    value={{
                                        key: data.plan_id,
                                        label: data.plan_name,
                                    }}
                                >
                                    {plans &&
                                        plans.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept._id}
                                                    key={dept._id}
                                                >
                                                    {dept.name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {errors && errors.plan && (
                                    <span className="error-text">
                                        {errors.plan[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label>
                                    Main Process Name
                                    <span className="error-text">*</span>
                                </label>
                                <Select
                                    allowClear
                                    // disabled
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelect(
                                            'main_process',
                                            value,
                                        )
                                    }
                                    onFocus={() => getMainProcess('')}
                                    showSearch
                                    onSearch={(value) => getMainProcess(value)}
                                    filterOption={false}
                                    value={{
                                        key: data.main_process_id,
                                        label: data.main_process_name,
                                    }}
                                >
                                    {mainProcess &&
                                        mainProcess.map((main) => {
                                            return (
                                                <Option
                                                    value={main.main_process}
                                                    key={main.main_process}
                                                >
                                                    {main.main_process}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {errors && errors.main_process && (
                                    <span className="error-text">
                                        {errors.main_process[0]}
                                    </span>
                                )}
                            </div>
                            {data.ng_prosess_names.map(
                                (ngProcessName, index) => (
                                    <div
                                        key={ngProcessName.id}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '8px',
                                        }}
                                    >
                                        <div
                                            className="form-group"
                                            style={{
                                                flex: 1,
                                                marginRight: '8px',
                                            }}
                                        >
                                            {index === 0 && (
                                                <div>
                                                    <span>NG Detail Name</span>
                                                    <span className="error-text">
                                                        *
                                                    </span>
                                                </div>
                                            )}
                                            <Input
                                                className={
                                                    errors && errors.name
                                                        ? 'is-error'
                                                        : ''
                                                }
                                                onChange={(e) =>
                                                    handleChangeDetails(
                                                        ngProcessName.id,
                                                        e.target.value,
                                                    )
                                                }
                                                style={{ width: '100%' }}
                                                value={
                                                    ngProcessName.ng_name ?? ''
                                                }
                                            />
                                            {errors && errors.name && (
                                                <span className="error-text">
                                                    {errors.name[0]}
                                                </span>
                                            )}
                                        </div>
                                        {index === 0 ? (
                                            <Button
                                                type="primary"
                                                onClick={handleAddRow}
                                                loading={loading}
                                                style={{
                                                    backgroundColor: '#1b2086',
                                                    borderColor: '#1b2086',
                                                }}
                                            >
                                                + Add
                                            </Button>
                                        ) : (
                                            <Button
                                                type="danger"
                                                onClick={() =>
                                                    handleDeleteRow(
                                                        ngProcessName.id,
                                                    )
                                                }
                                                loading={loading}
                                                style={{
                                                    backgroundColor: '#ff4d4f',
                                                    borderColor: '#ff4d4f',
                                                    marginBottom: '20px',
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </div>
                                ),
                            )}

                            <div
                                className="form-group"
                                style={{ marginTop: 40 }}
                            >
                                <Button
                                    type="primary"
                                    onClick={handleSave}
                                    loading={loading}
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                    }}
                                >
                                    {/* <i className="mdi mdi-content-save mr-5" />  */}
                                    Submit
                                </Button>

                                <Button
                                    htmlType="reset"
                                    type="primary"
                                    onClick={handleReset}
                                    // loading={loading}
                                    style={{
                                        backgroundColor: '#1b2086',
                                        marginLeft: 10,
                                        borderColor: '#1b2086',
                                    }}
                                >
                                    {/* <i className="mdi mdi-content-save mr-5" /> */}
                                    Reset
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    );
}

export default EditNg;
